import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'; // lang i18n

// 修改input组件默认有清空按钮
ElementUI.Input.props.clearable.default = true;
ElementUI.Card.props.shadow.default = 'hover';

// set ElementUI lang to EN
Vue.use(ElementUI, {
  locale: locale,
  size: 'normal'
});