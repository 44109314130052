import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import Header from './components/Header';
import routers from '@/router/routers';
export default {
  name: 'Layout',
  components: {
    Header: Header
  },
  computed: {
    cacheList: function cacheList() {
      // return routers.filter(item => item.name === 'App')[0].children.map(item => item.name);
      return this.$store.state.permission.addRoutes && this.$store.state.permission.addRoutes.children.map(function (item) {
        return item.name;
      });
    }
  }
};