var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper"
  }, [_c("Header"), _c("keep-alive", {
    attrs: {
      include: _vm.cacheList
    }
  }, [_c("router-view")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };