var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "layout-header flex items-center justify-between top"
  }, [_c("span", {
    staticClass: "title",
    on: {
      click: _vm.onHome
    }
  }, [_vm._v(_vm._s(_vm.platformName))]), _c("el-button", {
    staticClass: "close my-button",
    attrs: {
      loading: _vm.logoutLoading,
      circle: "",
      icon: "el-icon-switch-button"
    },
    on: {
      click: _vm.onLogout
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };