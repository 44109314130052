import _objectSpread from "/home/jenkins/agent/workspace/digitalnmn8f/digital-sz-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import Vue from 'vue';
import Router from 'vue-router';
import routes from './routers';
import store from '@/store';
import cache from '@/utils/cache';
Vue.use(Router);
var router = new Router({
  routes: routes,
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  }
});
var originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};
var HOME_PAGE_NAME = 'Home';
var LOGIN_PAGE_NAME = 'login';

/**
 * 导航守卫
 * to：目标路由对象；

 from：即将要离开的路由对象；

 next：他是最重要的一个参数，涉及到有next参数的钩子，必须调用next() 才能继续往下执行下一个钩子，否则路由跳转等会停止；

 注:如中断当前的导航要调用next(false)。如果浏览器的 URL 改变了 (可能是用户手动或者浏览器后退按钮)，那么 URL 地址会重置到from路由对应的      地址。（主要用于登录验证不通过的处理）
 */
router.beforeEach(function (to, from, next) {
  var token = cache.getToken();
  if (!token && to.name !== LOGIN_PAGE_NAME && to.name !== 'redirectLogin' && to.name !== 'login') {
    // 未登录且要跳转的页面不是登录页
    cache.clear();
    next({
      name: 'redirectLogin'
    });
  } else if (!token && to.name === LOGIN_PAGE_NAME) {
    // 未登陆且要跳转的页面是登录页
    next(); // 跳转
  } else if (token && to.name === LOGIN_PAGE_NAME) {
    // 已登录且要跳转的页面是登录页
    if (from && from.name) {
      next({
        name: from.name
      });
    } else {
      next({
        name: HOME_PAGE_NAME
      });
    }
  } else {
    // console.log('store.state.user.hasGetInfo：', store.state.user.hasGetInfo);
    if (store.state.user.hasGetInfo) {
      next();
    } else {
      store.dispatch('getUserInfo').then(function (result) {
        store.dispatch('buildRoutes').then(function (accessRoutes) {
          router.addRoutes([accessRoutes]); // 动态添加可访问路由表
          if (to.path == '/') {
            next({
              name: accessRoutes.children[0].name
            });
          } else {
            //replace 不能回退的跳转路由
            next(_objectSpread(_objectSpread({}, to), {}, {
              replace: true
            })); // hack方法 确保addRoutes已完成
          }
        });
      }).catch(function (error) {
        if (error.code && error.code === '999') {
          store.dispatch('handleLogout').then(function (result) {
            next({
              name: 'redirectLogin'
            });
          });
        } else {
          next();
        }
      });
    }
  }
});
export default router;