import "core-js/modules/es.json.stringify.js";
export default {
  set: function set(key, value) {
    if (value) {
      window.localStorage.setItem(key, value);
    } else {
      window.localStorage.removeItem(key);
    }
  },
  getToken: function getToken() {
    return window.localStorage.getItem('zz-token') || '';
  },
  setToken: function setToken(token) {
    this.set('zz-token', token);
  },
  removeToken: function removeToken() {
    window.localStorage.removeItem('zz-token');
  },
  getUser: function getUser() {
    var str = window.localStorage.getItem('zz-user');
    if (str) {
      return JSON.parse(str);
    } else {
      return null;
    }
  },
  setUser: function setUser(user) {
    this.set('zz-user', JSON.stringify(user));
  },
  removeUser: function removeUser() {
    window.localStorage.removeItem('zz-user');
  },
  getTenantCode: function getTenantCode() {
    return window.localStorage.getItem('zz-tenant-code') || 'shanghai';
  },
  getMenu: function getMenu() {
    return window.localStorage.getItem('menu');
  },
  setTenantCode: function setTenantCode(code) {
    this.set('zz-tenant-code', code);
  },
  getPlatformName: function getPlatformName() {
    return window.localStorage.getItem('zz-platform-name') || '';
  },
  setPlatformName: function setPlatformName(name) {
    this.set('zz-platform-name', name);
  },
  // removeTenantCode() {
  //   window.localStorage.removeItem('zz-tenant-code');
  // },
  clear: function clear() {
    this.removeToken();
    this.removeUser();
  }
};