import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import constantRoutes from '@/router/routers';
import { getRouters } from '@/api/menu';
import cache from '@/utils/cache';
import Layout from '@/layout';
var permission = {
  state: {
    routes: [],
    addRoutes: []
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = constantRoutes.concat(routes);
    }
  },
  actions: {
    // 生成路由
    buildRoutes: function buildRoutes(_ref) {
      var commit = _ref.commit;
      var params = {
        tenantCode: cache.getTenantCode()
      };
      return new Promise(function (resolve) {
        // 向后端请求路由数据
        getRouters(params).then(function (result) {
          //处理路由信息
          var accessedRoutes = filterAsyncRouter(result);
          // accessedRoutes.push({
          //   path: '*',
          //   redirect: '/404',
          //   meta: {
          //     hideInMenu: true
          //   }
          // })

          var s = {
            path: '/',
            name: 'app',
            redirect: accessedRoutes[0].path,
            component: Layout,
            children: accessedRoutes
          };
          commit('SET_ROUTES', s);
          resolve(s);
        });
      });
    }
  }
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  // console.log("asyncRouterMap",asyncRouterMap)
  return asyncRouterMap.filter(function (route) {
    if (route.component) {
      if (route.component === 'Layout') {
        //页面的整体架子
        route.component = Layout;
      } else {
        //跳转路由
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
}
export var loadView = function loadView(view) {
  // 路由懒加载
  return function (resolve) {
    return require(["@/".concat(view)], resolve);
  };
};
export default permission;